.contents{
width: auto;

}

.flex_box{
    display: flex;
    justify-content: center;
    align-items:center;
    height: 50vh;
    /*border: 1px solid #000;*/
    
}

.flex_item{

    padding: 20px;
    margin:  10px;              /*外側の余白*/
    border-radius:  5px;         /*角丸指定*/
    margin-bottom: 50;
}

.flex_item .Button{
    /*font-size: 20px;*/
    font-size: 1.5vw;
    white-space: nowrap;
}

.Button{
    /*height:100px;*/
    height:100%;
    max-height: 100px;
    /*width:220px;*/
    width:100%;
    max-width: 250px;
    position:relative;
    /*min-width: 200px;*/
    
}

.icon{
    width:4vw;
    max-width:100%;
    vertical-align:bottom;
}


.flex_item .exiticon{
    font-size:4vw;
    max-width:100%;
    vertical-align:bottom;
}


header .appbar{

    min-width: 1000px;
}

header .title{
    position:relative;
    z-index: 20;
    width:1000px;
    margin:auto;
    
}

header .syain_id{
    position:absolute;
    right:50px;
    font-weight:600;
 
}

.signout{
    position:absolute;
    bottom:300px;
}

header .signout_Button{
    height:40px;
    width:120px;
    position:absolute;
    left:50px;
}

/*スマホ向けレイアウト*/

/* ブレイクポイントが540px以下の時のメディアクエリを設定*/
/*タブレット1024px以下*/
@media (max-width:599px) {


.contents{
    /*display: block;*/
    width:auto;

}

.flex_box{
    display:block;
    margin:0 auto;

    /*position: relative;*/
    /*top: 100px;*/
    

    /*display:flex;*/
    /*flex-flow: column;*/
    /*align-items: center;*/
    
    /*height:60vh;*/
    /*border: 1px solid #000;*/
}

.flex_item .Button{
    font-Size:4vw;
    white-space: nowrap;
}

.Button{
    width:50vw;
}

.icon{
    width:10vw;
    max-width:100%;
    vertical-align:bottom;
}

.flex_item .exiticon{
    font-size:10vw;
    max-width:100%;
    vertical-align:bottom;
}


/*header .signout_Button{*/
/*    height:30px;*/
/*    width:80px;*/
/*    position:absolute;*/
/*    left:10px;*/
/*    font-size: xx-small;*/
/*    white-space: nowrap; 文字を改行させない*/
/*}*/



header .syain_id{

    right:10px;

}



}