.notstyle{
  
}

.betemp{
  background-color: #FF9999;
}

.besymp{
  background-color: #FFCC66;
}

.group{
  font-size: 25px;
  color:#1976d2;
  font-weight:600;
}

/*スマホ向けレイアウト*/

/* ブレイクポイントが540px以下の時のメディアクエリを設定*/
@media (max-width:599px) {
  
.group{
  font-size: 15px;
}

}