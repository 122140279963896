
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  text-align: center;
  /*min-width: 600px;*/
  width: auto;

}



.calender{
  position:relative;
  margin:auto;
  /*border: 5px solid #2933c2;*/
  /*z-index:20;*/
  width: auto;

}



#root {
  margin-top: 40px;
  width: auto;
}

header{
  width: auto;
}


header .AppBar{
  /*min-width:1000px;*/
  width: auto;
}


header .title{
  position:relative;
  z-index:20;
  /*width:1000px;*/
  width: auto;
  margin:auto;
}

header .syain_id{
  position:relative;
  /*right:50px;*/
  font-weight:600;
}

header .kimura_logo{
  vertical-align: middle;
  
  /*背景を不透明化して、白にする*/
  /*background-color: rgba(255,255,255,1.0);*/

}

/*button:active {*/
/*  position: relative;*/
/*  top: 4px;*/
/*  left: 4px;*/
/*  box-shadow: none;*/
/*}*/


h2{
  color:#1976d2;
  text-align: left;
  font-size: 35px;
  margin-right: 50px;
  margin: 0 auto;
  width: 25%;
  max-width: 500px;

    
}

input{
  /*display: inline-block;*/
  text-align: center;
  width: 130px;
  height: 40px;
  border: 5px solid #2933c2;
  /*margin-right: 10px;*/
  font-size: 150%; /*テキストボックスの文字の大きさ*/
  vertical-align:middle;/*ボタンと文字の位置を中央にそろえる*/
  
}

.input{
position: relative;
left:50px;

}



.pairs {
 /*width: 100%;
 display: flex;
 justify-content: center;*/
  margin: 16px auto;
	text-align: center;
	display: block;
	border: 1px solid #000;

}

.texts{
 /*text-align: right;*/
  text-align: left;
	display: inline-block;
}

.date{
  font-size: 35px;
  color:#1976d2;
  font-weight:600;
  margin-bottom:50;

  
}

.calender .textfield{
  font-size: 35px;
  color:#1976d2;
  font-weight:600;
  margin-bottom:50;
  cursor : pointer;  
}

.date > .ArrowIcon{
  font-size: 100px;
  margin:-0.4em -0.2em;
  /*height: 1em;*/
  /*zoom:5;*/
  /*padding: 0;*/
  /*margin: 0;*/

}

.calender > .ArrowIcon{
  font-size: 100px;
  margin:-0.55em -0.3em;
  /*height: 1em;*/
  /*zoom:5;*/
  /*padding: 0;*/
  /*margin: 0;*/
  cursor : pointer; 
}


form{
  font-size: 35px;
  width:auto;
  /*border: 1px solid #000;*/
  /*padding:0px 35%;*/
  text-align: left;/*入力フォームの「文字」と「入力欄」を左寄せ*/
  position:relative;/*フォーム全体位置の微調整*/

  display:flex;
  flex-flow: column;
  align-items: center;

  color:#1976d2;
  font-weight:600;
  margin:0 auto;
  /*display:inline-block;*/
  /*margin-left: 155px;*/
  /*margin-left: 15%;*/
}

form dl{
  /*border: 1px solid #000;*/
  width: auto;
}

form dl dt{
  /*border: 1px solid #000;*/
  /*width:230px;*/
  width: auto;
  /*width: 80%;*/
  padding:10px 0;
  float:left;
  clear:both;

}

form dl dd{
  /*border: 1px solid #000;*/
  padding:10px 0;
  /*width: 700px;*/
  margin: 0;
  display: flex;
  width: auto;

  
}

.symptom{
  /*right:35px;*/
  white-space: nowrap;
  width: auto;

}

/*体温入力inputの中に「℃」の文字を表示*/
::placeholder {
  font-size: 0.7em;
  text-align: right;
}


input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
} 

input[type="number"] { 
  -moz-appearance:textfield; 
} 

.radiobutton{

  bottom: 4px;
  position: relative;
  
}

.modal{
  position: absolute;
  /*top: 50%;*/
  top:90%;
  left: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* ↑浮かせる設定 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height:200px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 6px 3px rgba(107,107,107,.10);
  color: #1976d2;
  border-radius: 10px;
  text-align: center;
  
}


/*スマホ向けレイアウト*/

/* ブレイクポイントが540px以下の時のメディアクエリを設定*/
@media (max-width:599px) {

.contents{
  width: auto;

}
  
.date{
  font-size: 15px;
  transform: scale(0.8);
}

.calender .textfield{
  /*font-size: 15px;*/
  font-size: 5vw;
}


form{
  /*font-size: 15px;*/
  font-size: 5vw;
  width: auto;
  /*border: 1px solid #000;*/
  transform: scale(0.8);


}

form dl dt{
  width: 150px;
  /*width: 100%;*/
  /*border: 1px solid #000;*/
}

/*form dl dd{*/
  /*width: 300px;*/
  /*width: auto;*/
  /*border: 1px solid #000;*/
  /*margin: 0;*/
  /*padding: 0;*/

    
/*}*/

.symptom{
  /*border: 1px solid #000;*/
  /*font-size: 30px;*/
  /*display: inline;*/
  /*white-space: pre;*/
  transform: scale(0.6);
  font-size: 7vw;

}


header .title{
  font-size: 5vw;
}


header .syain_id{
  right:10px;
  /*font-size: 10px;*/
  font-size: 2vw;
}

/*登録ボタンの大きさ*/
.button{
  width: 20vw;
}

/*登録ボタンの文字の大きさ*/
.contentsItem{
  font-size: 3vw;
}

.modal{
 top:100%;
}

}