#tableheader .thead{
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}

#tablebody .tbody{
    font-size: x-large;
    text-align: center;
}

#tableheader .lcell{
    width: 10rem;
}

.syain_name {
    font-size: x-large;
    font-weight: bold;
}


.linkstyle_btn{
  cursor: pointer;
  border: none;
  background: none;
  color: #0033cc;
  font-size: x-large;
}

.linkstyle_btn:hover{
  text-decoration: underline;
  color: #002080;
}

/*スマホ向けレイアウト*/

/* ブレイクポイントが540px以下の時のメディアクエリを設定*/
@media (max-width:599px) {

#searchArea > .id{
    display:flex;
    flex-flow: column;
    align-items: center;
}

#tableheader .thead{
    font-size: 5vw;
    font-weight: bold;
    text-align: center;
}

#tablebody .tbody{
    font-size: 5vw;
    text-align: center;
}

#tableheader .lcell{
    width: 5vw;
}

.syain_name {
    font-size: 5vw;
    font-weight: bold;
}


.linkstyle_btn{
  cursor: pointer;
  border: none;
  background: none;
  color: #0033cc;
  font-size: 5vw;
}

.linkstyle_btn:hover{
  text-decoration: underline;
  color: #002080;
}





}