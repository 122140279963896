.date{
  font-size: 35px;
  color:#1976d2;
  font-weight:600;
  margin-bottom:50;
}

.Calendar .textfield{
  font-size: 35px;
  color:#1976d2;
  font-weight:600;
  margin-bottom:50;
  cursor : pointer;  
}

.date > .ArrowIcon{
  font-size: 100px;
  margin:-0.4em -0.2em;
  /*height: 1em;*/
  /*zoom:5;*/
  /*padding: 0;*/
  /*margin: 0;*/

}

.Calendar > .ArrowIcon{
  font-size: 100px;
  margin:-0.55em -0.3em;
  /*height: 1em;*/
  /*zoom:5;*/
  /*padding: 0;*/
  /*margin: 0;*/
  cursor : pointer; 
}

/*スマホ向けレイアウト*/

/* ブレイクポイントが540px以下の時のメディアクエリを設定*/
@media (max-width:599px) {
  
.date{
  /*font-size: 15px;*/
  font-size: 5vw;
  transform: scale(0.8);
}

.Calendar .textfield{
  /*font-size: 15px;*/
  font-size: 5vw;
}

}